import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";

@Injectable({ providedIn: "root" })
export class TemplatePageTitleStrategy extends TitleStrategy {
  public currenttitle = "Calendr";
  constructor(private readonly title: Title) {
    super();
  }
  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    this.currenttitle = title;
    if (title !== undefined) {
      this.title.setTitle(`Calendr | ${title}`);
    } else {
      this.title.setTitle("Calendr");
    }
  }
}
